<template>
  <section class="row" id="about">
    <!--==============About text ============-->

    <div
      class="col-6 col-sm-8 col-xs-12 mx-auto text-center my-3 px-3"
      id="about-text"
    >
      <h1 class="text-center text-capitalize  fw-bold display-6 mb-2 pb-0">
        Get to know me
      </h1>
      <p class="mt-4">
        A frontend Web applications
        developer currently learning to build fullstack web and mobile solutions with MySQL,  Vue, Node and Express.
      </p>

      <a
        href="./Adeoye-Adefemi-Opeoluwa-CV.pdf"
        style="background: var(--gradient)"
        download
      ></a>
      Download CV
    </div>
  </section>
</template>

<script>
export default {
  name: "About",
};
</script>



<style scoped>
#about-text {
  padding: 0 10px;
}

#about-text p {
  padding: 0 15px;
}

#about-text {
  margin-bottom: 5rem;
}

button {
  margin-top: 1.2rem;
}
@media screen and (max-width: 600px) {
  #about-text {
    padding: 5px;
    width: 80vw;
    margin: 0 auto;
  }

  #about-text h1 {
    margin-bottom: 2.5vh;
  }
}
</style>