<template>
  <div>
    <nav
      class=" fixed shadow-lg mt-0"
      :class="{ onScroll: !view.topOfPage }"
      id="navbar"
    >
      <router-link class="nav-link d-block" :to="{ name: 'home' }">
      <!-- <img src="./../assets/logo.svg" alt="logo"> -->
      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="115" height="26" viewBox="0 0 115 26">
  <defs>
    <clipPath id="clip-path">
      <rect id="Rectangle_37" data-name="Rectangle 37" width="115" height="26" transform="translate(9479 1053)" :fill="currentColor"/>
    </clipPath>
  </defs>
  <g id="Mask_Group_5" data-name="Mask Group 5" transform="translate(-9479 -1053)" clip-path="url(#clip-path)">
    <text id="Opeoluwa." transform="translate(9537 1072)" :fill="currentColor" font-size="20" font-family="Poppins-Bold, Poppins" font-weight="500"><tspan x="-56.61" y="0">Opeoluwa</tspan><tspan y="0" fill="#df56ad" font-size="35" font-family="Poppins-SemiBold, Poppins" font-weight="600">.</tspan></text>
  </g>
</svg>

      </router-link>
      <b-button v-b-toggle.sidebar-1>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          :stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="feather feather-menu"
        >
          <line x1="3" y1="12" x2="21" y2="12"></line>
          <line x1="3" y1="6" x2="21" y2="6"></line>
          <line x1="3" y1="18" x2="21" y2="18"></line>
        </svg>
      </b-button>
    </nav>

    <b-sidebar id="sidebar-1" title="Opeoluwa" shadow>
      <router-link class="nav-link d-block" :to="{ name: 'home', path: '/' }">
        Home
      </router-link>
      <router-link class="nav-link d-block" :to="{ name: 'about' }">
        About
      </router-link>
      <router-link class="nav-link d-block" :to="{ name: 'projects' }">
        Projects
      </router-link>
      <router-link class="nav-link d-block" :to="{ name: 'technologies' }">
        Technologies
      </router-link>
      <router-link class="nav-link d-block" :to="{ name: 'contact' }">
        Contact
      </router-link>
    </b-sidebar>
  </div>
</template>
<script>
export default {
  data() {
    return {
      view: {
        topOfPage: true,
        currentColor: "#fff",
      },
    };
  },
  beforeMount() {
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      if (window.pageYOffset > 0) {
        if (this.view.topOfPage) {
          this.view.topOfPage = false;
          this.currentColor = "var(--default-dark-color)";
        }
      } else {
        if (!this.view.topOfPage) this.view.topOfPage = true;
        this.currentColor = "#fff";
      }
    },
  },
};
</script>



<style scoped>
  #navbar {
    position: fixed;
    z-index: 1000;
    margin: 0;
    padding: 10px !important;
    width: 100% !important;
    align-items: center !important;
    display: flex;
    transition: all 450ms 50ms ease-in-out;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 15px;
  }

  .onScroll {
    transition: 300ms ease-in-out;
    background: var(--light);
    display: block;
  }

  #navbar > .container {
    display: flex;
    align-items: center !important;
    align-content: center;
  }

button {
  background: transparent;
  color: #fff;
  border: none;
}
button:hover {
  background: transparent;
  color: #fff;
}
button *:hover {
  border: none;
  outline: none;
}
a {
  color: var(--background);
  font-size: 17px;
  font-weight: 300;
  transition-property: font-weight;
  transition-delay: 20ms;
  transition-duration: 250ms;
  transition-timing-function: ease-in-out;
}

a:hover,
a:active {
  font-weight: bolder;

  color: var(--background) !important;
}
</style>