<template>
     <div id="social-links">
      <a href="https://www.linkedin.com/in/adeoye-adefemi-0669b41b5">
        <img src="./../assets/linkedin.svg"
      /></a>
      <a href="https://twitter.com/_opeolluwa">
        <img src="./../assets/twitter.svg" />
      </a>
      <a href="https://github.com/opeolluwa">
        <img src="./../assets/github.svg" />
      </a>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>

</style>