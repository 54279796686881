<template>
  <div>
      Lorem ipsum dolor, sit amet consectetur adipisicing elit. Nesciunt adipisci minus aspernatur labore ratione vero, optio nobis, quos quo accusamus veniam iure corrupti inventore, voluptate perspiciatis eius dignissimos repellendus consectetur.
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>