<template>
  <section class="section row">
    <div class="col-xs-12 col-md-6 col-lg-6">
      <h2 class="text-center text-capitalize fw-bold display-6 mb-2 pb-0">
        get in Touch
      </h2>
      <p>Let&apos;s make your next web application a reality.</p>
    </div>
    <!------------------------------CONTACT FORM----------------------------->

    <div class="px-2 m-auto col-xs-12 col-md-6 col-lg-6 img-fluid center">
      <form @submit.prevent="sendEmail">
        <label>Name</label>
        <input type="text" v-model="name" name="name" placeholder="Your Name" />
        <label>Subject</label>
        <input
          type="text"
          v-model="subject"
          name="subject"
          placeholder="Subject"
        />
        <label>Email</label>
        <input
          type="text"
          v-model="email"
          name="email"
          placeholder="Your Email"
        />
        <label>Message</label>
        <textarea
          name="message"
          v-model="message"
          cols="30"
          rows="5"
          placeholder="Message"
        ></textarea>
        <span class="text-danger" v-if="response.error">{{
          response.error
        }}</span>
        <span class="text-success" v-else>{{ response.status }}</span>

        <button type="submit" :disabled="isMessageSent">Send</button>
      </form>
    </div>
  </section>
</template>

<script>
import axios from "axios";
export default {
  name: "Contact",
  data() {
    return {
      name: "",
      email: "",
      message: "",
      subject: "",
      isMessageSent: false,
      response: [],
    };
  },
  methods: {
    async sendEmail() {
      this.isMessageSent = true;
      const { email, message, name, subject } = this;
      const payload = { email, message, name, subject };
      const response = await axios.post("/mails/send/", {
        payload,
      });
      this.response = response.data;
      if (!response.data.error) {
        this.name = "";
        this.email = "";
        this.message = "";
        this.subject = "";
      }
    },
  },
};
</script>

<style scoped>
* {
  box-sizing: border-box;
}

label {
  float: left;
}
input[type="text"],
[type="email"],
textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 6px;
  margin-bottom: 16px;
}

button[type="submit"] {
  background: var(--gradient) !important;
  width: 100% !important;
  color: white;
  padding: 12px 20px;
  border: 1px solid var(--border);
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 200ms ease-in-out;
  outline: none;
}

button[type="submit"]:hover {
  background-color: var(--gradient);
}

.section {
  text-align: center;
  border-radius: 5px;
}

a {
  color: #fff;
  text-decoration: none;
}
svg {
  margin-right: 10px;
  width: 20px;
  display: inline-block;
}
</style>