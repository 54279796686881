<template>
  <section class="section" id="projects">
    <h2>My projects</h2>
  </section>
</template>

<script>
export default {};
</script>

<style>
#projects {
  min-height: 100vh !important;
}
</style>