<template>
  
  <section class="section row" id="home">
    <!--hero text-->
    <div class="col-sm-6 col-md-6 col-lg-6 col-xl-6 pt-0 mt-0" id="hero-text">
      <p class="lead">Hi, I&apos;m</p>
      <h1 class="text-bold">Opeoluwa</h1>
      <p class="mt-1 lead">
        A JavaScript
        <br />Developer, Instructor  and Evangelist. 
      </p>

      <!--call to action-->

      <div
        class="d-grid gap-2 d-md-flex justify-content-md-start mt-4 pt-4 cta"
      >
        <router-link
          to="/contact"
          class="btn btn-primary btn-lg px-4 me-md-2"
          id="primary-cta"
        >
          Contact
        </router-link>
        <router-link
          to="/projects"
          class="btn btn-outline-secondary btn-lg px-4"
        >
          Explore Projects
        </router-link>
      </div>
    </div>

    <!--===============HERO IMAGE===========================-->
    <div class="col-sm-6 col-md-6 col-lg-6 col-xl-6 img-fluid">
      <img
        src="./../assets/hero-image.png"
        alt="Opeoluwa"
        class="img-fluid d-block mx-auto my-4"
      />
    </div>

    <!--====================HERO SECTION END===================-->
  </section>
</template>

<script>
export default {
  name: "Hero",
};
</script>


<style scoped>
#home {
  background: var(--background);
  background-image: url(./../assets/hero-background.svg);
  padding-top: 9.5vh;
  background-origin: border-box;
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
}
#hero-text {
  align-self: center;
}
.break {
  display: block;
}
.img-fluid {
  max-width: 90%;
}
#hero-text p {
  padding: 0;
  margin: 0;
  font-size: 24px;
  text-align: left;
}

#hero-text h1 {
  font-weight: 600;
}

.cta a {
  /* box-shadow: 0 5px 7px rgba(127, 127, 127, 0.5); */
  transition-property: transform;
  transition-duration: 500ms;
  transition-delay: 10ms;
  transition-timing-function: ease-in-out;
  text-decoration: none;
  text-align: center;
}
.cta a:hover {
  transform: scale(0.75);
  transition-duration: 500ms;
  transition-delay: 10ms;
  transition-timing-function: ease-in-out;
}
#primary-cta {
  background: var(--gradient);
  border: 1px solid var(--border);
}

#secondary-cta {
  border: 3px solid #6c12da;
}

@media screen and (max-width: 600px) {
  #home {
  background-image: url(./../assets/hero-background.svg);
  padding-top: 15vh;
  background-origin: border-box;
  background-position: center center;
  background-repeat: no-repeat;
}
  #hero {
    display: flex;
    height: 100vh;
  }
  #social-links {
    top: 75vh;
  }
  #hero-text {
    align-self: center;
  }
  #hero-text p {
    font-size: 1rem;
    text-align: center !important;
    margin: 0 0.5rem;
    padding: 0 12px;
  }
  .break {
    display: block;
  }
  #hero-text h1 {
    font-weight: 600;
    font-size: 2.5rem;
  }
  #hero-text {
    text-align: center !important;
    margin: 0 0.35rem;
  }

  .cta a {
    text-decoration: none;
    padding: 3px;
  }

  #primary-cta {
    display: none;
  }

  #secondary-cta {
    background: transparent;
    border-width: 2px;
    border-style: solid;
    border-color: #750ff1;
  }

  /*====================SOCILA LINKS======================*/

  #social-links svg {
    width: 1.75rem;
  }
}
</style>

