<template>
  <section class="row py-4 mt-4" id="skillset">
    <!--==========================Programming Languages=================-->
    <div class="m-0 pt-4">
      <h1 class="text-center text-capitalize display-6 fw-bold mb-2 pb-0">
        My Tool Set
      </h1>
      <div class="mx-2 text-center" id="skills">
        <p v-for="skill in skills" :key="skill.skill_id">
          <a :href="skill.skill_official_website" target="_blank">
            <img
              :src="skill.skill_icon_url"
              :alt="skill.skill_name"
              class="img-fluid d-inline-block"
            />
          </a>
        </p>
      </div>
    </div>
  </section>
</template>




<style scoped>
@keyframes fadein {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

div#skills {
  display: grid;
  grid-template-columns: auto auto auto;
  flex-wrap: wrap !important;
  align-items: center;
  align-content: center;
  justify-content: center;
  padding: 8px;
}

a {
  transition-property: opacity;
  transition-duration: 900ms;
  transition-delay: 10ms;
  transition-timing-function: ease-in-out;
  opacity: 1;
  display: block;
  margin: 3px 5px;
  width: 50px;
  height: 50px;
  cursor: pointer;
}

.w-100 {
  width: 80vw;
}
</style>


<script>
import axios from "axios";
export default {
  data() {
    return {
      skills: [],
    };
  },

  async mounted() {
    //load in skills from api
    const _skills = await axios.get(
      "https://opeolluwa-proxies.mdbgo.io/skills"
    );
    this.skills = _skills.data.rows;
  },
};
</script>